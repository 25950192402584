import { debounce, isArray, values } from 'lodash';
import { parse, stringify } from 'querystring';
import { withStyles } from '@material-ui/core';
import {
	BooleanInput,
	Filter,
	NumberInput,
	ReferenceInput,
	SelectInput,
	translate
} from 'react-admin';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { InputAdornment } from '@material-ui/core';

import { AutocompleteInput, SearchInput } from '../components';
import {
	ROLE_COLLECTOR_GUY,
	ROLE_AGENT,
	ROLE_OFFICE_EMPLOYEE,
	ROLE_WAREHOUSE_MANAGER,
	ROLE_MAIN_CUSTOMER,
	ROLE_CUSTOMER,
	ROLE_SUPER_ADMIN
} from '../users/roles';
import { IN_WAREHOUSE, NOT_IN_WAREHOUSE } from './statuses';
import httpClient from '../providers/httpClient';

import { OBJECT_TYPE, PALLET_TYPE, CARTON_TYPE, COLLECTION_TYPE } from './articleTypes';
import CustomSelectProjectInput from './CustomSelectProjectInput';
import CustomSelectStorageAreaInput from './CustomSelectStorageAreaInput';
import { connect } from 'react-redux';

const styles = {
	warehousesInput: {
		flex: '1 0 1px',
		'& .changed-value': {
			paddingLeft: '0!important'
		}
	}
};

const getFromStorage = filterField =>
	JSON.parse(localStorage.getItem(`last-filter-${filterField}`));

const setToStorage = (filterField, choices) => {
	return localStorage.setItem(`last-filter-${filterField}`, JSON.stringify(choices));
};

class ArticleFilter extends Component {
	_isMounted = false;

	state = {
		names: getFromStorage('names') || [],
		manufacturers: getFromStorage('manufacturers') || [],
		colors: getFromStorage('colors') || []
	};

	constructor(props) {
		super(props);

		this.debounceFetchNames = debounce(this.fetchNames, 500);
		this.debounceFetchManufacturers = debounce(this.fetchManufacturers, 500);
		this.debounceFetchColors = debounce(this.fetchColors, 500);
	}

	componentDidMount = async () => {
		this._isMounted = true;
	};

	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchChoices = async (value, pluralField, singleField) => {
		if (value === undefined) {
			return getFromStorage(pluralField) || [];
		}

		const filterValues = { ...this.props.filterValues };
		if (filterValues[singleField]) {
			delete filterValues[singleField];
		}

		if (filterValues.isScratch) {
			delete filterValues.isScratch;
		}

		const query = stringify({
			...filterValues,
			q: value
		});

		const response = await httpClient(
			`${process.env.REACT_APP_API_URL}/article/${pluralField}?${query}`,
			{
				method: 'GET'
			}
		);

		if (response) {
			let choices = response.json;

			choices = (isArray(choices) ? choices : values(choices)).map(choice => ({
				id: choice,
				name: choice
			}));

			if (
				!choices.length &&
				getFromStorage(pluralField) &&
				getFromStorage(pluralField)[0].name === value
			) {
				return getFromStorage(pluralField);
			}

			return choices;
		} else {
			return [];
		}
	};

	fetchColors = async ({ value }) => {
		const colors = await this.fetchChoices(value, 'colors', 'colors');

		if (this._isMounted) {
			this.setState({ colors });
		}
	};

	fetchManufacturers = async ({ value }) => {
		const manufacturers = await this.fetchChoices(value, 'manufacturers', 'manufacturer');

		if (this._isMounted) {
			this.setState({ manufacturers });
		}
	};

	fetchNames = async ({ value }) => {
		const names = await this.fetchChoices(value, 'names', 'name');

		if (this._isMounted) {
			this.setState({ names });
		}
	};

	hideFilter = filterName => {
		const { hideFilter } = this.props;
		const displayedFilters = parse(localStorage.getItem('displayed_articles_filters')) || {};

		if (displayedFilters[filterName]) {
			delete displayedFilters[filterName];
		}

		localStorage.setItem('displayed_articles_filters', stringify(displayedFilters));

		hideFilter(filterName);
	};

	referenceFilter = field => {
		const filterValues = { ...this.props.filterValues };
		if (filterValues[field]) {
			delete filterValues[field];
		}

		const referenceFilter = {};
		Object.keys(filterValues).forEach(key => {
			referenceFilter[`articles.${key}`] = filterValues[key];
		});

		return referenceFilter;
	};

	removeFocus = () => {
		localStorage.removeItem('current_focus');
	};

	onChangeAutocomplete = filterField => (e, val) => {
		setToStorage(filterField, [{ id: val, name: val }]);
	};

	categoryFilter = () => {
		const { permissions } = this.props;
		const inOrNot =
			permissions === ROLE_WAREHOUSE_MANAGER ||
			permissions === ROLE_OFFICE_EMPLOYEE ||
			permissions === ROLE_AGENT ||
			permissions === ROLE_SUPER_ADMIN
				? { 'articles.status][in][': [NOT_IN_WAREHOUSE, IN_WAREHOUSE] }
				: permissions === ROLE_CUSTOMER || permissions === ROLE_MAIN_CUSTOMER
				? { 'articles.status][in][': [IN_WAREHOUSE] }
				: permissions === ROLE_COLLECTOR_GUY
				? { 'articles.status][in][': [NOT_IN_WAREHOUSE] }
				: {};

		return {
			...this.referenceFilter('category'),
			'articles.deletedAt][isNull': 1,
			'articles.barcodes.deletedAt][isNull': 1,
			...inOrNot
		};
	};

	render() {
		const {
			permissions,
			classes,
			// eslint-disable-next-line no-unused-vars
			dispatch,
			filters,
			translate,
			currentFocus,
			// eslint-disable-next-line no-unused-vars
			hideFilter,
			// eslint-disable-next-line no-unused-vars
			showFilter,
			...props
		} = this.props;
		const { names, manufacturers, colors } = this.state;

		const projectFilter = this.referenceFilter('project');
		const storageareaFilter = this.referenceFilter('storagearea');

		return (
			<Filter hideFilter={this.hideFilter} showFilter={this.showFilter} {...props}>
				{filters.q && (
					<SearchInput
						label="resources.general.fields.search"
						placeholder={translate('resources.general.fields.search')}
						source="q"
						alwaysOn
						onFocus={() => this.setFocusTo('q')}
						onBlur={this.removeFocus}
						autoFocus={currentFocus === 'q'}
					/>
				)}
				{filters.qid && (
					<NumberInput
						label="QID"
						source="barcode_id"
						onFocus={() => this.setFocusTo('barcode_id')}
						onBlur={this.removeFocus}
						autoFocus={currentFocus === 'barcode_id'}
					/>
				)}
				{filters.serialNumber && (
					<NumberInput
						label="Serial Number"
						source="serialNumber"
						onFocus={() => this.setFocusTo('serialNumber')}
						onBlur={this.removeFocus}
						autoFocus={currentFocus === 'serialNumber'}
					/>
				)}
				{filters.name && (
					<AutocompleteInput
						onSuggestionsFetchRequested={this.debounceFetchNames}
						label="resources.articles.fields.name"
						placeholder={translate('resources.articles.fields.name')}
						source="name"
						choices={names}
						onChange={this.onChangeAutocomplete('names')}
					/>
				)}
				{filters.category && (
					<ReferenceInput
						source="category"
						label="resources.articles.fields.category"
						reference="article-categories"
						filter={this.categoryFilter()}
						allowEmpty={false}
						sort={{
							field: 'name',
							order: 'ASC'
						}}
						perPage={10000}
					>
						<SelectInput />
					</ReferenceInput>
				)}
				{filters.project && permissions !== ROLE_COLLECTOR_GUY && (
					<ReferenceInput
						source="project"
						reference="projects"
						filter={{
							...projectFilter,
							'articles.deletedAt][isNull': 1,
							'articles.barcodes.deletedAt][isNull': 1
						}}
						perPage={1000}
					>
						<CustomSelectProjectInput />
					</ReferenceInput>
				)}
				{filters.type && (
					<SelectInput
						label="resources.articles.fields.type"
						placeholder={translate('resources.articles.fields.type')}
						source="type"
						allowEmpty={false}
						choices={[CARTON_TYPE, PALLET_TYPE, OBJECT_TYPE, COLLECTION_TYPE].map(type => ({
							id: type,
							name: translate(`resources.articles.type.${type}`)
						}))}
					/>
				)}
				{filters.manufacturer && (
					<AutocompleteInput
						onSuggestionsFetchRequested={this.debounceFetchManufacturers}
						label="resources.articles.fields.manufacturer"
						placeholder={translate('resources.articles.fields.manufacturer')}
						source="manufacturer"
						choices={manufacturers}
						onChange={this.onChangeAutocomplete('manufacturers')}
					/>
				)}
				{filters.storagearea && permissions !== ROLE_COLLECTOR_GUY && (
					<ReferenceInput
						source="storagearea"
						reference="storageareas"
						className={classes.warehousesInput}
						filter={storageareaFilter}
						perPage={3000}
						sort={{
							field: 'path',
							order: 'ASC'
						}}
					>
						<CustomSelectStorageAreaInput />
					</ReferenceInput>
				)}
				{filters.isScratch && (
					<BooleanInput label="resources.articles.fields.is_scratch" source="isScratch" />
				)}
				{filters.width && (
					<NumberInput
						label="resources.articles.fields.width"
						source="width"
						onFocus={() => this.setFocusTo('width')}
						onBlur={this.removeFocus}
						autoFocus={currentFocus === 'width'}
						InputProps={{
							endAdornment: <InputAdornment position="end">cm</InputAdornment>,
							inputProps: {
								min: 0,
								step: 0.01
							}
						}}
					/>
				)}
				{filters.height && (
					<NumberInput
						label="resources.articles.fields.height"
						source="height"
						onFocus={() => this.setFocusTo('height')}
						onBlur={this.removeFocus}
						autoFocus={currentFocus === 'height'}
						InputProps={{
							endAdornment: <InputAdornment position="end">cm</InputAdornment>,
							inputProps: {
								min: 0,
								step: 0.01
							}
						}}
					/>
				)}
				{filters.length && (
					<NumberInput
						label="resources.articles.fields.length"
						source="length"
						onFocus={() => this.setFocusTo('length')}
						onBlur={this.removeFocus}
						autoFocus={currentFocus === 'length'}
						InputProps={{
							endAdornment: <InputAdornment position="end">cm</InputAdornment>,
							inputProps: {
								min: 0,
								step: 0.01
							}
						}}
					/>
				)}
				{filters.colors && (
					<AutocompleteInput
						onSuggestionsFetchRequested={this.debounceFetchColors}
						label="resources.articles.fields.colors"
						placeholder={translate('resources.articles.fields.colors')}
						source="colors"
						choices={colors}
						onChange={this.onChangeAutocomplete('colors')}
					/>
				)}
			</Filter>
		);
	}

	setFocusTo = field => {
		localStorage.setItem('current_focus', field);
	};

	showFilter = (filterName, defaultValue) => {
		const { showFilter } = this.props;
		const displayedFilters = parse(localStorage.getItem('displayed_articles_filters')) || {};

		if (!displayedFilters[filterName]) {
			displayedFilters[filterName] = true;
		}

		localStorage.setItem('displayed_articles_filters', stringify(displayedFilters));

		showFilter(filterName, defaultValue);
	};
}

const mapStateToProps = (state, { displayedFilters }) => {
	const storeDisplayedFilters = parse(localStorage.getItem('displayed_articles_filters')) || {};

	return {
		currentFocus: localStorage.getItem('current_focus') || 'q',
		displayedFilters: {
			...displayedFilters,
			...storeDisplayedFilters
		}
	};
};

const enhance = compose(withStyles(styles), translate, connect(mapStateToProps, undefined));

export default enhance(ArticleFilter);
